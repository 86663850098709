$primary-color: #30646b; // primary color for all components
$link-color: #30646b; // link color
$primary-color-hover: #317c86;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
  .p-delete {
    position: absolute;
    color: none;
    top: 0px;
    left: 0px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    &:hover {
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      color: red;
      background: rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    button.ant-btn.ant-btn-link.ant-btn-sm {
      overflow: visible;
      opacity: 0;
      &:hover {
        opacity: 1;
        color: red;
      }
    }
  }
  a {
    color: $link-color;
  }
}

.ant-page-header {
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background-color: rgba($primary-color, 0.1);
  color: #333;
  font-weight: 500;
  .ant-menu.ant-menu-sub.ant-menu-inline {
    background-color: transparent;
  }
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: rgba($primary-color, 0.1);
  padding: 10px 0;
  li {
    margin: 0;
  }
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  color: $primary-color;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: $primary-color;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #333;
}
.ant-menu-dark .ant-menu-item-selected > span > a {
  color: $primary-color;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #90bae0;
  border-right: 2px solid;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: $link-color;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: $primary-color;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background-color: $primary-color;
}
.ant-menu.ant-menu-dark
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background-color: $primary-color;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: $primary-color;
}
.form-create-task .ant-form-item {
  margin-bottom: 15px !important;
}
.ictees-menu {
  margin-right: 128px;
  &.collapsed {
    margin-right: 20px;
  }
  @media screen and(max-width: 767px) {
    margin-right: 5px;
  }
}
.ant-drawer-right .ant-drawer-content-wrapper {
  @media screen and(max-width: 767px) {
    width: 100% !important;
  }
}
.ant-carousel .slick-dots li button {
  background: rgb(106, 150, 18);
}

.ant-carousel .slick-dots li.slick-active button {
  background: #000;
}

.ant-table {
  .ant-table-thead {
    th {
      padding: 15px;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 10px 15px;
  }
}
.ant-card {
  .ant-card-head {
    min-height: 40px;
    padding: 0 20px;
    .ant-card-head-title {
      padding: 10px 0;
    }
  }
  .ant-card-body {
    padding: 20px;
  }
  .ant-card-extra {
    padding: 10px 0;
  }
}
.custom-file {
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }
}
.ant-table-thead {
  text-transform: uppercase;
}
.custom-label {
  .ant-form-item-label {
    text-align: left;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: $primary-color;
  border-color: $primary-color;
}
.ant-btn-primary {
  background: $primary-color;
  border-color: $primary-color;
  color: #fff;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: $primary-color-hover;
  border-color: $primary-color;
  color: #fff;
}

.ant-input-search-enter-button input:hover,
.ant-input-search-enter-button input:focus {
  border-color: $primary-color;
}
.ant-input:hover {
  border-color: $primary-color;
}
.ant-input-affix-wrapper:hover {
  border-color: $primary-color;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: $primary-color;
}
.ant-pagination-item-active a {
  color: $primary-color;
}
a {
  color: $primary-color;
}
.ant-pagination-item-active {
  border-color: $primary-color;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: $primary-color;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  border-color: $primary-color;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: $primary-color;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: $primary-color;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $primary-color;
}
.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $primary-color;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: $primary-color;
}
// .ant-tabs-tab{
//   padding: 12px 20px;
// }
.ant-tabs-tab.ant-tabs-tab-active {
  color: $primary-color;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: $primary-color;
}
.ant-tabs-tab:hover {
  color: #90bae0;
}
// check box
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color-hover;
  border-color: $primary-color;
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $primary-color-hover;
}
.ant-checkbox .ant-checkbox-checked {
  border-color: $primary-color-hover;
}

.product-upload {
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    height: 116px;
  }
}

.ant-table-selection-extra {
  right: -15px;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  text-transform: capitalize;
}
.ant-btn-link {
  border: none;
}

.variant-product {
  .ant-card-body {
    padding: 0;
  }
}
.uploadPrintFiles {
  .ant-upload.ant-upload-select-picture-card {
    width: fit-content;
    height: fit-content;
    margin-right: 0;
    margin-bottom: 0;
    border: none;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0;
  }
}

.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  border: 1px dotted $primary-color;
}

/// mapping

.ant-row.ant-form-item.form-select {
  span.ant-select-selection-item {
    overflow: unset;
    white-space: break-spaces;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
  }
}
.ant-select-item-option-content {
  overflow: unset;
  white-space: break-spaces;
}
